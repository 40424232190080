<template>
  <div class="library">
    <div class="title">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <!-- <el-form-item label="所属组织">
          <AgentTree
            :props="props"
            clearable
            style="width: 300px"
            @change="handleChangeUnit"
          >
          </AgentTree>
        </el-form-item> -->
        <el-form-item label="创建时间">
          <el-date-picker
            v-model="searchForm.time"
            clearable
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="primary" @click="goAdd">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="name" fixed width="150" label="活动名称">
        </el-table-column>
        <!-- <el-table-column prop="unitNameList" width="300" label="所属组织">
          <template slot-scope="{ row }">
            {{row.unitNameList.join('>')}}
            所有用户
          </template>
        </el-table-column>
        <el-table-column prop="typeDesc" label="内容类型" width="130">
        </el-table-column> -->
        <el-table-column prop="enableStatusDesc" label="启用状态">
        </el-table-column>
        <el-table-column prop="content" label="弹窗内容">
          <template slot-scope="{ row }">
            <el-image :src="row.imageSrc" fit="fill" style="width: 100px" alt="" />
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="150">
        </el-table-column>
        <el-table-column fixed="right" width="200" label="操作">
          <template slot-scope="{ row }">
            <el-button type="text" @click="goEdit(row)">编辑</el-button>
            <el-button type="text" @click="del(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="beginIndex"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { queryHaiLiUnitTree } from "@/api/common";
import {
  getMarketingActivityList,
  deleteMarketingActivity
} from "@/api/marketing";
import { timeToDate } from "@/utils/date-format";
import { handleEditTree } from "@/utils/tool";
import AgentTree from '@/components/AgentTree'
export default {
  data() {
    return {
      searchForm: {
        unitNo: "",
        time: ""
      },
      // agentList: [], //所属组织数据
      startTime: "",
      endTime: "",
      beginIndex: 1,
      pageSize: 20,
      list: [],
      total: 0,
      props: {
        value: "unitNo",
        label: "name"
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.onSearch()
      }
    };
    this.getMarketingActivityList();
    // this.queryHaiLiUnitTree();
  },
  beforeRouteEnter (to, from, next) {
    if (from.path === '/leaseholdMgmt/addActivity') {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false
    }
    next()
  },
  activated () {
    if (this.$route.meta.isBack) {
      this.$route.meta.isBack = false // 重置详情页标识isBack
    }
    this.getMarketingActivityList();
  },
  methods: {
    onSearch() {
      this.beginIndex = 1;
      this.getMarketingActivityList();
    },
    handleChangeUnit(value) {
      this.searchForm.unitNo = value[value.length - 1];
    },
    async getMarketingActivityList() {
      if (this.searchForm.time) {
        this.startTime = this.searchForm.time[0];
        this.endTime = this.searchForm.time[1];
      } else {
        this.startTime = null;
        this.endTime = null;
      }
      let page = this.beginIndex;
      page = page - 1;
      const params = {
        ...this.searchForm,
        startTime: String(Date.parse(this.startTime)),
        endTime: String(Date.parse(this.endTime)),
        beginIndex: page,
        pageSize: this.pageSize
      };
      if (!this.endTime || !this.startTime) {
        params.startTime = null;
        params.endTime = null;
      }
      delete params.time;
      const res = await getMarketingActivityList(params);
      res.ldata.forEach(el => {
        if (el.createTime == "null") {
          el.createTime = null;
        }
        if (el.createTime && el.createTime != "null") {
          el.createTime = timeToDate(el.createTime);
        }
        el.loading = false;
      });
      this.list = res.ldata;
      this.total = res.mdata.total || 0;
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.getMarketingActivityList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },
    /**删除 */
    del(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const params = {
            number: row.number
          };
          await deleteMarketingActivity(params);
          this.$message.success("删除成功");
          this.beginIndex = 1;
          this.getMarketingActivityList();
        })
        .catch(() => {});
    },
    goAdd() {
      this.$store.dispatch('login/checkHandle', '/managementPlatform/admin/createOrUpdateMarketingActivity' ).then((flag) => {
        if(flag) {
          this.$router.push("/leaseholdMgmt/addActivity");
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push("/leaseholdMgmt/addActivity");
      })
      
    },
    goEdit(row) {
      sessionStorage.setItem('imageSrc', row.imageSrc)
      this.$store.dispatch('login/checkHandle', '/managementPlatform/admin/createOrUpdateMarketingActivity' ).then((flag) => {
        if(flag) {
          this.$router.push({
            path: "/leaseholdMgmt/addActivity",
            query: {
              number: row.number,
              type: "edit",
              name: row.name,
              enableStatus: row.enableStatus,
              unitNo: row.unitNo
            }
          });
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push({
          path: "/leaseholdMgmt/addActivity",
          query: {
            number: row.number,
            type: "edit",
            name: row.name,
            enableStatus: row.enableStatus,
            unitNo: row.unitNo
          }
        });
      })
    }
  },
  components: {
    AgentTree,
  }
};
</script>

<style scoped>
.library {
  padding-bottom: 80px;
}
</style>
